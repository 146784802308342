<template>
  <div style="display: flex; flex: 1; flex-direction: column">
    <div class="table-inner my-table">
      <resizable-table
        :busy="$apollo.queries.WriteOffs.loading"
        style="margin-top: 0 !important"
        :inside_card="false"
        table_name="egais-ttn"
        empty_text="Ничего не найдено"
        :items="items"
        :default_fields="fields"
        @row-click="rowClicked"
      >
        <template #head_id>
          <!--          <div class="center">-->
          <!--            <b-checkbox v-model="select_all" />-->
          <!--          </div>-->
        </template>
        <template #body_id="data">
          <div
            class="line-status"
            :class="{
              success: data.item.status === 'success',
              warning: data.item.status === 'warning'
            }"
          ></div>
          <!--          <b-checkbox v-model="selected" :value="data.value" />-->
        </template>
        <template #body_number="data">
          <div>
            <div>{{ data.item.number }}</div>
          </div>
        </template>
        <template #body_date="data">
          <div>
            <div class="d-flex">{{ formatDate(data.item.date) }}</div>
          </div>
        </template>
        <template #body_operation>
          <div>
            <div class="d-flex">Списание</div>
          </div>
        </template>
        <template #body_internal_number="data">
          <div>
            <div>{{ data.item.document?.number }}</div>
          </div>
        </template>
        <template #body_date_internal="data">
          <div>
            <div class="d-flex">{{ formatDate(data.item.document?.date) }}</div>
          </div>
        </template>
        <template #body_status="data">
          <b-badge
            class="bstatuses"
            :class="{
              success: data.item.status === 'Confirm',
              warning: data.item.status === 'Rejected'
            }"
          >
            {{
              data.item.status === 'Confirm'
                ? 'Проведена в ЕГАИС'
                : data.item.status === 'Rejected'
                ? 'Есть проблемы'
                : 'Ожидает ответ от егаис'
            }}
          </b-badge>
        </template>
      </resizable-table>
    </div>
    <pagination
      :total="total"
      items_name="documents"
      doc_type="egais"
      @change_pagination="handlerPagination"
    />
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import formatDate from '@/utils/formatDate'
  import Pagination from '../../../shared/components/table/Pagination.vue'
  export default {
    name: 'EgaisTable',
    components: {
      Pagination
    },
    props: {},
    apollo: {
      WriteOffs: {
        query: require('../../gql/writeOff.graphql'),
        variables() {
          return {
            input: {
              branch: this.currentBranch.id,
              documentPagination: this.pagination
            }
          }
        },
        result({ data }) {
          this.items = data.WriteOffs?.heads
          this.total = data.WriteOffs?.total
        }
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    },
    data() {
      return {
        pagination: { skip: 0, take: 20 },
        total: 0,
        items: [],
        selected: [],
        select_all: false,
        fields: [
          // {
          //   key: 'id',
          //   label: '',
          //   checked: true,
          //   width: 40
          // },
          // {
          //   key: 'number',
          //   label: '№ Документа',
          //   checked: true,
          //   width: 200
          // },
          {
            key: 'date',
            label: 'Дата регистрации',
            checked: true,
            width: 200
          },
          {
            key: 'operation',
            label: 'Операция',
            checked: true,
            width: 200
          },
          {
            key: 'internal_number',
            label: 'Внутренний № документа',
            checked: true,
            width: 200
          },
          {
            key: 'date_internal',
            label: 'Дата внутреннего документа',
            checked: true,
            width: 200
          },
          {
            key: 'status',
            label: 'Статус',
            checked: true,
            width: 200
          }
        ]
      }
    },
    watch: {
      select_all(newVal) {
        if (!newVal) return (this.selected = [])

        this.items.forEach((item) => {
          const id = item.id
          if (!this.selected.includes(id)) this.selected.push(id)
        })
      }
    },
    methods: {
      formatDate(date) {
        return formatDate(new Date(date))
      },
      rowClicked(item) {
        this.$router.push(`/ext-systems/egais/document/${item.id}`)
      },
      handlerPagination({ current_page = null, take = null }) {
        if (current_page) this.pagination.skip = Number((current_page - 1) * take)
        if (take) this.pagination.take = take
        this.$apollo.queries.WriteOffs.refetch()
      }
    }
  }
</script>
<style scoped lang="scss">
  ::v-deep .td-inner span {
    padding: 16px !important;
  }
  ::v-deep .row-field {
    border-bottom: 1px solid #e1e1e1;
    border-right: none !important;
  }
  .my-table {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: white;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #e1e1e1;
  }

  ::v-deep() {
    td.text-nowrap {
      position: relative;
    }
  }

  .bstatuses {
    padding: 4px 12px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    background: #efefef;
    color: #888888;
    &.success {
      background: #ebf9f5;
      color: #00cb91;
    }
    &.warning {
      background: #fffae7;
      color: #ffa800;
    }
  }

  .line-status {
    width: 2px;
    height: 32px;
    position: absolute;
    left: 0;
    top: 50%;
    background: #bcbcbc;
    border-radius: 0px 1px 1px 0px;
    transform: translate(0%, -50%);

    &.success {
      background: #00cb91;
    }
    &.warning {
      background: #ffa800;
    }
  }

  .table-inner {
    width: 100%;
  }
</style>
