var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "b-table__header"
  }, [_c('div', {
    staticClass: "b-table__filter"
  }, [_c('date-picker', {
    staticClass: "hidden-picker",
    attrs: {
      "clearable": false,
      "type": "daterange",
      "open": _vm.datepicker_open,
      "editable": false,
      "format": "dd.MM.yyyy",
      "placeholder": "Рабочий период"
    },
    on: {
      "on-change": function ($event) {
        return _vm.setPeriod({
          period: 'custom'
        });
      }
    },
    model: {
      value: _vm.filter_date_range,
      callback: function ($$v) {
        _vm.filter_date_range = $$v;
      },
      expression: "filter_date_range"
    }
  }), _c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "background": "white",
            "padding": "8px 10px",
            "display": "flex",
            "flex-direction": "row",
            "height": "32px",
            "border": "1px solid #bcbcbc"
          }
        }, [_c('span', {
          staticStyle: {
            "color": "black"
          }
        }, [_vm._v(_vm._s(_vm.getPeriod))]), _c('img', {
          staticClass: "ml-2",
          attrs: {
            "src": "/img/icons/calendar-dark.svg",
            "alt": "calendar"
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.datepicker_open = true;
      }
    }
  }, [_vm._v(" Указать период ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'today'
        });
      }
    }
  }, [_vm._v(" Сегодня ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'yesterday'
        });
      }
    }
  }, [_vm._v(" Вчера ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'week'
        });
      }
    }
  }, [_vm._v(" Текущая неделя ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'lweek'
        });
      }
    }
  }, [_vm._v(" Прошлая неделя ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'month'
        });
      }
    }
  }, [_vm._v(" Текущий месяц ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'lmonth'
        });
      }
    }
  }, [_vm._v(" Прошлый месяц ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'lyear'
        });
      }
    }
  }, [_vm._v(" Последние 365 дней ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: null
        });
      }
    }
  }, [_vm._v(" Все время ")])], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }