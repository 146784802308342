<template>
  <div class="b-table__wrapper">
    <egais-header @date-range="updateDateRange" />
    <div class="mt-3 table-content">
      <egais-table />
    </div>
    <table-navbar
      :items="selected"
      items_name="egais-ttn-doc"
      @remove_items="remove_items"
    />
    <pagination
      :total="items.length"
      table_name="egais-ttn"
      @change_pagination="handlerPagination"
    />
  </div>
</template>
<script>
  import Pagination from '../../shared/components/table/Pagination.vue'
  import TableNavbar from '../../shared/components/table/TableNavbar.vue'
  import EgaisHeader from '../components/egais/EgaisHeader.vue'
  import EgaisTable from '../components/egais/EgaisTable.vue'
  export default {
    name: 'Egais',
    components: {
      EgaisHeader,
      EgaisTable,
      TableNavbar,
      Pagination
    },
    props: {},
    data() {
      return {
        selected: [],
        items: [
          {
            id: 1,
            number: 121333,
            date_reg: new Date().toLocaleDateString(),
            operation: 'Списание',
            internal_number: '23412413',
            date_internal: new Date(2017, 11, 10).toLocaleDateString(),
            status: 'success'
          },
          {
            id: 2,
            number: 349348985,
            date_reg: new Date().toLocaleDateString(),
            operation: 'Списание',
            internal_number: '23412413',
            date_internal: new Date(2015, 8, 10).toLocaleDateString(),
            status: 'draft'
          },
          {
            id: 3,
            number: 4208509,
            date_reg: new Date().toLocaleDateString(),
            operation: 'Списание',
            internal_number: '23412413',
            date_internal: new Date(2030, 3, 10).toLocaleDateString(),
            status: 'warning'
          }
        ]
      }
    },
    methods: {
      updateDateRange(date) {
        this.table.isBusy = true
        this.data_range = date
        this.$apollo.queries.InventarisationHeads.refresh()
      },
      handlerPagination({ current_page = null, take = null }) {
        this.table.isBusy = true
        if (current_page) this.pagination.skip = Number((current_page - 1) * take)
        if (take) this.pagination.take = take
      },
      async remove_items() {
        // await this.$apollo.mutate({
        //   mutation: require('./gql/RemoveInventoryHead.graphql'),
        //   variables: {
        //     input: { ids: items }
        //   }
        // })
        // this.$apollo.queries.InventarisationHeads.refetch()
        this.selected = []
      }
    }
  }
</script>
<style lang="scss" scoped>
  .b-table {
    &__wrapper {
      .table-content {
        flex-direction: column;
        display: flex;
        flex: 1;
      }
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    &__content {
      display: flex;
      flex: 1;
    }
  }
</style>
