var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "flex",
      "flex": "1",
      "flex-direction": "column"
    }
  }, [_c('div', {
    staticClass: "table-inner my-table"
  }, [_c('resizable-table', {
    staticStyle: {
      "margin-top": "0 !important"
    },
    attrs: {
      "busy": _vm.$apollo.queries.WriteOffs.loading,
      "inside_card": false,
      "table_name": "egais-ttn",
      "empty_text": "Ничего не найдено",
      "items": _vm.items,
      "default_fields": _vm.fields
    },
    on: {
      "row-click": _vm.rowClicked
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return undefined;
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (data) {
        return [_c('div', {
          staticClass: "line-status",
          class: {
            success: data.item.status === 'success',
            warning: data.item.status === 'warning'
          }
        })];
      }
    }, {
      key: "body_number",
      fn: function (data) {
        return [_c('div', [_c('div', [_vm._v(_vm._s(data.item.number))])])];
      }
    }, {
      key: "body_date",
      fn: function (data) {
        return [_c('div', [_c('div', {
          staticClass: "d-flex"
        }, [_vm._v(_vm._s(_vm.formatDate(data.item.date)))])])];
      }
    }, {
      key: "body_operation",
      fn: function () {
        return [_c('div', [_c('div', {
          staticClass: "d-flex"
        }, [_vm._v("Списание")])])];
      },
      proxy: true
    }, {
      key: "body_internal_number",
      fn: function (data) {
        var _data$item$document;

        return [_c('div', [_c('div', [_vm._v(_vm._s((_data$item$document = data.item.document) === null || _data$item$document === void 0 ? void 0 : _data$item$document.number))])])];
      }
    }, {
      key: "body_date_internal",
      fn: function (data) {
        var _data$item$document2;

        return [_c('div', [_c('div', {
          staticClass: "d-flex"
        }, [_vm._v(_vm._s(_vm.formatDate((_data$item$document2 = data.item.document) === null || _data$item$document2 === void 0 ? void 0 : _data$item$document2.date)))])])];
      }
    }, {
      key: "body_status",
      fn: function (data) {
        return [_c('b-badge', {
          staticClass: "bstatuses",
          class: {
            success: data.item.status === 'Confirm',
            warning: data.item.status === 'Rejected'
          }
        }, [_vm._v(" " + _vm._s(data.item.status === 'Confirm' ? 'Проведена в ЕГАИС' : data.item.status === 'Rejected' ? 'Есть проблемы' : 'Ожидает ответ от егаис') + " ")])];
      }
    }])
  })], 1), _c('pagination', {
    attrs: {
      "total": _vm.total,
      "items_name": "documents",
      "doc_type": "egais"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }