var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-table__wrapper"
  }, [_c('egais-header', {
    on: {
      "date-range": _vm.updateDateRange
    }
  }), _c('div', {
    staticClass: "mt-3 table-content"
  }, [_c('egais-table')], 1), _c('table-navbar', {
    attrs: {
      "items": _vm.selected,
      "items_name": "egais-ttn-doc"
    },
    on: {
      "remove_items": _vm.remove_items
    }
  }), _c('pagination', {
    attrs: {
      "total": _vm.items.length,
      "table_name": "egais-ttn"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }